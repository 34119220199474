@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body, #root, .App-footer {
    background-color: #ffffff !important;
}
.App {
  width:100%;
  background-color: #ffffff;
}

.App-header {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: end;
    background-color: #282c34;
    color: #ffffff !important;
    top: 0;
    left:0;
    position: fixed !important;
}

    .App-header h3 {
        margin-left: 20%;
    }

    @media (max-width: 991px) {
        .App-header h3 {
            margin-left: 5%;
            margin-right: 22%;
        }
    }

    .App-header a, .App-header a:hover {
        text-decoration: none;
        color: #ffffff;
    }

    .App-header li a {
        color: #ffffff;
        font-size: 20px;
    }

.page_header {
    min-height: 80px;
}

.home_header {
    margin-top: 73px;
    width: 100%;
    color: white;
    text-align: center;
    display: flex;
}

    .home_header img {
        width: 65%;
    }

    .home_header .overlay {
        background-color: #000000f0;
        width: 35%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
    }

    .home_header .section-divider {
        border-bottom: 2px solid white;
        width:50%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .home_header button {
        height: 50px;
        border-radius: 5px;
    }

@media (min-width: 992px) {
    .home_header {
        height: 33.5vw;
    }
}

@media (max-width: 991px) {
    .home_header {
        flex-direction: column;
    }

        .home_header img {
            width: 100%;
        }

        .home_header .overlay {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
        }
}

.home_wwo {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .home_wwo .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .home_wwo img {
        border-radius: 50%;
        width: 20vw;
        height: 20vw;
        object-fit: cover;
        margin-bottom: 20px;
    }

    .home_wwo button {
        height: 100px;
        border-radius: 5px;
        width: 300px !important;
        margin: 20px auto !important;
    }

    .home_wwo .description {
        padding: 20px 50px;
    }

.home_products {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .home_products .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .home_products img {
        width: 19vw;
        height: 19vw;
        object-fit: cover;
        margin-bottom: 20px;
    }

    .home_products button {
        height: 50px;
        border-radius: 5px;
        width: 150px !important;
        margin: 20px auto !important;
    }

    .home_products .description {
        padding: 20px 50px;
    }

.about {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .about .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .about .col-lg-8 {
        max-width: 600px;
    }

    .about .bg-img {
        background-position: center;
        background-size: cover;
        color: #ffffff;
        height: 18vw;
    }

    @media (max-width: 991px) {
        .about .bg-img {
            height: 18vh;
        }
    }

    .about h1, .about h2 {
        background-color: #000000a0;
    }

    button {
        border-radius: 5px !important;
        height: 50px;
        width: 150px;
    }

.products_products {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .products_products .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .products_products img {
        height: 40vw;
        object-fit: cover;
        margin-bottom: 20px;
    }

    .products_products button {
        height: 50px;
        border-radius: 5px;
        width: 150px !important;
        margin: 20px auto !important;
    }

    .products_products .description {
        padding: 20px 50px;
    }

.App-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white !important;
    padding: 100px 0 50px 0;
    width: 100%;
}

    .App-footer a {
        text-decoration: none;
        color: #282c34;
    }

.clear {
    clear: both;
}

.navbar-nav {
    margin: auto !important;
}

.hamburger {
    position: absolute !important;
    right: 40px;
    height: 70px;
}

.centered {
    margin: auto;
    text-align: center;
}
    
    .centered td {
        padding: 10px;
    }
